import { Brand } from '@finn/ua-vehicle';
import { CustomSliderItem } from '@finn/ui-cosmic';
import { getCloudinaryImgUrl, getCloudinarySrcSet } from '@finn/ui-utils';
import get from 'lodash/get';
import slugify from 'slugify';

import { BrandSliderItem } from '~/types/slider';

export const convertBrandsToSliderItems = (brands: Brand[]) => {
  return brands
    .filter((brand: Brand) => brand.helper_brand_logo?.url)
    .map((brand: Brand) => {
      const cloudinaryURL = getCloudinaryImgUrl(
        get(brand, 'helper_brand_logo.url', ''),
        {
          h: 56,
          dpr: 2,
        }
      );

      return {
        image: cloudinaryURL,
        srcSet: getCloudinarySrcSet(get(brand, 'helper_brand_logo.url', ''), [
          56,
        ]),
        url: `/subscribe/${slugify(brand.id, { lower: true })}`,
        key: brand.id,
      } as BrandSliderItem;
    });
};

export const convertCosmicSliderItemsToBrandItems = (
  items: CustomSliderItem[]
) => {
  return items
    .filter((item) => item?.image?.url)
    .map((item, index) => {
      const cloudinaryURL = getCloudinaryImgUrl(item.image.url, {
        h: 56,
        dpr: 2,
      });

      return {
        image: cloudinaryURL,
        srcSet: getCloudinarySrcSet(item.image.url, [56]),
        url: item?.link || '#',
        key: item?.alt_text || String(index),
      } as BrandSliderItem;
    });
};
